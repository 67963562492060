import React, { Component, Fragment } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { AumentaNivelGovBr } from "../AumentaNivelGovBr/AumentaNivelGovBr";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { SubscriberStatus } from "../Subscriber/Status"
import {getMessagemErro} from "../../utils/erro";
import PdfViewer from "../PdfViewer";
import api from "../../services/api";
import {getUrlAutorizacaoIti, getUrlRetorno} from "../../utils/itiUtil"
import { strTobase64, base64ToStr } from "../../utils/base64Util";
import qs from 'query-string';
import './Billing.css';
import { InformacaoCertificado } from "../AumentaNivelGovBr/InformacaoCertificado";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }
const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user
	}
}

const CryptoJS = require("crypto-js");
const pathRetorno = "/billing/agreement";

export const BillingAgreement =
withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {

		constructor(props){
			super(props);
			this.state = {
                faturamentoOrgao: {},
				prestacaoContasPDF : null,
                nomeArquivo: '',
                tiposCertificado: [],
				tipoCertificado: '0',
				botoesHabilitados : true,
				prestacaoContasAssinadaSucesso: false,
			};

			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleAssinarPrestacaoContas = this.handleAssinarPrestacaoContas.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
						<Message />
						<Loading />
						
						<div  style={{margin: "10px"}}>
							<h1>Atestar Declaração de Consumo</h1>
						</div>

					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			const id = this.props.match.params.id;
            if(! id || (isNaN(id))){
                this.props.exibeMensagemErro("Id do Faturamento não informado corretamente");
                return;
            }

			const state = localStorage.getItem('state');
			localStorage.removeItem('state');

			const urlParams = qs.parse(this.props.location.search);
			const code = urlParams.code;
			const error = urlParams.error;

			if((code || error) && state){
				const objState = JSON.parse(base64ToStr(state));
				objState.prestacaoContasPDF = Buffer.from(objState.prestacaoContasPDF, 'base64').buffer;
				this.setState(objState);

				if(!error){
					const body = {
						id: objState.faturamentoOrgao.id,
						tipoCertificado: objState.tipoCertificado,
						urlRetorno: getUrlRetorno(`${pathRetorno}/${this.state.faturamentoOrgao.id}`),
						code
					}

					this.assinaPrestacaoContas(body);
				}
				return;
			} 

            this.carregaTiposCertificadosDigitais();
			this.carregaFaturamentoOrgao(id);
            this.baixarPrestacaoContasPDF(id)
		}

		renderForm() {
            const {tipoCertificado, prestacaoContasPDF, nomeArquivo} = this.state;
			return(
                <Fragment>
                    <div className="container-fluid">

						<AumentaNivelGovBr disabled={!this.state.botoesHabilitados}/>

						{ tipoCertificado === this.state.ID_CERTIFICADO_ICPBRASIL &&
							<div className="status">
								<SubscriberStatus/>
							</div>
						}

					    <div className="br-form">
                            <div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label htmlFor="tipoCertificado">
												Certificado: &nbsp;
												<InformacaoCertificado/>
											</label>
											<select name="tipoCertificado" onChange={this.handleInputChange} value={this.state.tipoCertificado} disabled={this.isNivelConfiabilidadeBronze()}>
												{
													this.state.tiposCertificado?.map(tipoCertificado =>
														<option 
															key={tipoCertificado.id} 
															value={tipoCertificado.id}>
															{tipoCertificado.nome}
														</option>
												)}
											</select>
										</div>
									</div>
								</div>
                            </div>

							<div className="container-pdf-viewer">
								<PdfViewer arquivo={prestacaoContasPDF} nomeArquivo={nomeArquivo}/>
							</div>
							<br/>

							<div className="actions-button">
								<div className="actions justify-content-start">
									{(! this.state.prestacaoContasAssinadaSucesso && this.state.faturamentoOrgao.situacao === 'P') &&
									<button type="button" className="button is-primary" onClick={this.handleAssinarPrestacaoContas} disabled={!this.state.botoesHabilitados}>Assinar</button>
									}
									<Link to="/"> 
										<div className="back-button">
											<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
										</div>
									</Link>
								</div>
							</div>

                        </div>
                    </div>
					
                </Fragment>
			);
		}

        carregaTiposCertificadosDigitais(){
			api.get('/api/chave-acesso-api/tipos-certificados')
				.then(response => { 
					const tiposCertificado = response.data;
					let ID_CERTIFICADO_ICPBRASIL = null;
					let ID_CERTIFICADO_GOVBR = null;

					for(var tipo of tiposCertificado){
						if(tipo['icpBrasil'] === true){
							ID_CERTIFICADO_ICPBRASIL = String(tipo.id);
						}

						if(tipo['govbr'] === true){
							ID_CERTIFICADO_GOVBR = String(tipo.id);
						}
					}
					const tipoCertificado = this.isNivelConfiabilidadeBronze() ? ID_CERTIFICADO_ICPBRASIL : ID_CERTIFICADO_GOVBR;

					this.setState({tiposCertificado, tipoCertificado, ID_CERTIFICADO_ICPBRASIL, ID_CERTIFICADO_GOVBR}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar tipos certificados digitais " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		carregaFaturamentoOrgao(id){
			api.get(`/api/prestacao-contas/${id}`)
				.then(response => { 
					this.setState({faturamentoOrgao: response.data}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar a declaração de consumo " + erro)
					this.props.exibeMensagemErro("Erro durante o carregamento da página")
				});
		}

		baixarPrestacaoContasPDF(id){
            const options = {
				method: 'post',
				url: `/api/prestacao-contas/relatorio/pdf/${id}`,
				responseType: 'arraybuffer'
			}

			this.props.limpaMensagem();
			this.props.mostraAguardando();
			this.setState({ botoesHabilitados : false });

			api(options)
				.then((response) => {
                    let nomeArquivo = 'RelatorioPrestacaoContas.pdf';

					const contentDisposition = response['headers']['content-disposition'];
					if(contentDisposition) {
						nomeArquivo = contentDisposition.split("filename=")[1];
					}

                    this.setState({
                        prestacaoContasPDF: response.data, 
                        nomeArquivo,
                    })
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de declaração de consumo';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		isNivelConfiabilidadeBronze() {
			return this.props.user.nivelConfiabilidade === 'BRONZE';
		}

		handleAssinarPrestacaoContas(e){
			this.props.limpaMensagem();

			try{
				this.validaFormulario();
			}catch(erroValidacao){
				e.preventDefault();
				return;
			}

			const tipoCertificado = this.state.tipoCertificado;
			if(tipoCertificado === this.state.ID_CERTIFICADO_ICPBRASIL) {
				this.assinarPrestacaoContasToken();
			} else if(tipoCertificado === this.state.ID_CERTIFICADO_GOVBR) {
				this.assinarPrestacaoContasAPIGovbr();
			}
		}

		assinarPrestacaoContasAPIGovbr(){
			api.get('/api/chave-acesso-api/verifica-token-iti')
				.then(response => { 
					const possuiTokenAtivo = response.data;
					if(possuiTokenAtivo === true) {
						const body = {
							id: this.state.faturamentoOrgao.id,
							tipoCertificado: this.state.tipoCertificado,
							tokenItiAtivo: true,
						}

						this.assinaPrestacaoContas(body);
					}else {
						this.obtemAutorizacaoIti();
					}
				})
				.catch(erro => {
					console.log("Não foi possível verificar o token iti do usuário", JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante a verificação do token do usuário");
				});
		}

		obtemAutorizacaoIti() {
			const stateCopy = {...this.state};
			stateCopy.prestacaoContasPDF = Buffer.from(stateCopy.prestacaoContasPDF).toString('base64');

			const state = strTobase64(JSON.stringify(stateCopy));
			localStorage.setItem('state', state);
			window.location.replace(getUrlAutorizacaoIti(`${pathRetorno}/${this.state.faturamentoOrgao.id}`))
		}

		getHashEmBase64(message){
			const wordArray = CryptoJS.lib.WordArray.create(message);
			const hash = CryptoJS.SHA256(wordArray);
			return CryptoJS.enc.Base64.stringify(hash);
		}

		assinarPrestacaoContasToken () {
			this.setState({botoesHabilitados:false});
			this.props.mostraAguardando();
			const {prestacaoContasPDF} = this.state;
			const base64 = this.getHashEmBase64(prestacaoContasPDF);
			window.scrollTo(0, 0);

			window.SerproSignerClient.sign('hash', base64, 'UTF-8', false)
				.success((response)=>{
					const body = {
						assinatura: response.signature,
						id: this.state.faturamentoOrgao.id,
						tipoCertificado: this.state.tipoCertificado,
					}

					this.assinaPrestacaoContas(body);
				})
				.error((e)=>{
					let mensagem = e;
					if(e.error){
						mensagem = e.error;
					}
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
					this.props.exibeMensagemErro("Não foi possível assinar a declaração de consumo. " + mensagem);
					console.log(mensagem);
				});
		}

		assinaPrestacaoContas(body) {
			this.setState({botoesHabilitados:false});
			this.props.mostraAguardando();
			api.post('/api/prestacao-contas/assinar', body)
				.then(response => {
					this.setState({ prestacaoContasAssinadaSucesso: true });
					this.props.exibeMensagemSucesso("Declaração de consumo assinada com sucesso.");
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível assinar a declaração de consumo:", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		validaFormulario(){
			let mensagens = [];
			const tipoCertificado = this.state.tipoCertificado;
			if(!tipoCertificado){
				mensagens.push({mensagem: "Certificado é obrigatório."})
			}

			let assinadorConectado = window.SerproSignerClient && window.SerproSignerClient.isConnected() === true;

			if (this.state.ID_CERTIFICADO_ICPBRASIL === tipoCertificado && ! assinadorConectado){
				this.props.exibeMensagemErro("Assinador Serpro não está ativo.");
				throw new Error("Assinador Serpro inativo.");
			} 
			
			if(mensagens.length > 0){
				this.props.exibeMensagemErro("Não foi possível realizar a operação porque há campos obrigatórios não preenchidos:", mensagens)
				throw new Error("Formulário não preenchido corretamente.");
			}
		}

    }
) // redux
); // router