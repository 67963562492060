import React, { Component } from "react";
import { Fragment } from "react";
import { connect } from 'react-redux';
import config from "../../config";
import './AumentaNivelGovBr.css';

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user
	}
}

export const AumentaNivelGovBr =

connect(
	(mapStateToProps), 
	(null)
)(

	class extends Component {

		render() {
			return (
				<Fragment>
					{this.props.user.nivelConfiabilidade === 'BRONZE' &&
						<div>
							<p>SUA CONTA GOV.BR É NÍVEL <b>BRONZE</b></p>
							<img className="imagem-nivel" src={require('../../images/barra-bronze.svg')} alt="Nível bronze"/>
							<p><b>A conta gov.br possui três níveis: ouro, prata e bronze.</b></p>
							<p>Migre para o nível <b>ouro</b> ou <b>prata</b> e assine o termo de responsabilidade com o assinador GOV.BR!</p>
							<br/>
							<div className="br-form">
								<div className="actions-button">
									<div className="actions">
										<button type="button" className="button is-primary" disabled={this.props.disabled} onClick={this.aumentaNivelContaGovBr}>Aumentar nível</button>
									</div>
								</div>
							</div>
						</div>	
					}
				</Fragment>
			);
		}

		aumentaNivelContaGovBr() {
			localStorage.clear();
			sessionStorage.clear();
			window.location.href=`${config.govbrConfiabilidadesUrl}?client_id=${config.govbrClientId}&niveis=(2/3)`
		}

	}
);