const PERMISSAO_ORGAO = "ORGAO";
const PERMISSAO_GESTOR = "GESTOR";
const PERMISSAO_APLICACAO_FORNECEDORA = "API";
const PERMISSAO_PLANO = "PLANO";
const PERMISSAO_PERFIL_GESTOR= "PERFIL_GESTOR";
const PERMISSAO_TERMO_USO = "TERMO_USO";
const PERMISSAO_SOLICITACAO_RELATORIO = "SOL_RELATORIO";
const PERMISSAO_CADASTRO_WHITELIST = "SOL_WHITELIST";
const PERMISSAO_RELATORIO = "RELATORIO";
const PERMISSAO_MONITORACAO= "MON";
const PERMISSAO_APLICACAO_CONSUMIDORA = "APLICACAO";
const PERMISSAO_FATURAMENTO = "FATURAMENTO";

export {PERMISSAO_ORGAO, PERMISSAO_GESTOR, PERMISSAO_APLICACAO_FORNECEDORA, PERMISSAO_PLANO, 
        PERMISSAO_PERFIL_GESTOR, PERMISSAO_TERMO_USO, PERMISSAO_SOLICITACAO_RELATORIO, PERMISSAO_RELATORIO, 
        PERMISSAO_MONITORACAO, PERMISSAO_APLICACAO_CONSUMIDORA, PERMISSAO_FATURAMENTO, PERMISSAO_CADASTRO_WHITELIST}