import React, {Fragment, useState} from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import './styles.css'; 

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


export default function PdfViewer (props) {

    const [totalPaginas, setTotalPaginas] = useState(null);
    const [paginaAtual, setPaginaAtual] = useState(1);

    function onDocumentLoadSuccess({ numPages: quantidadePaginas }) {
        setTotalPaginas(quantidadePaginas);
        setPaginaAtual(1);
    }
    
    function irPrimeiraPagina() {
        setPaginaAtual(1);
    }

    function irUltimaPagina() {
        setPaginaAtual(totalPaginas);
    }

    function mudaPagina(offset) {
        setPaginaAtual(prevPageNumber => prevPageNumber + offset);
    }
    
    function irPaginaAnterior() {
        mudaPagina(-1);
    }
    
    function irPaginaPosterior() {
        mudaPagina(1);
    }

    function download(arquivo) {
        var element = document.createElement('a');
        element.setAttribute('href', URL.createObjectURL(new Blob([arquivo])));
        element.setAttribute('download', props.nomeArquivo ? props.nomeArquivo : 'arquivo.pdf');
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <Fragment>
            {props.arquivo && 
                <Fragment>
                    <div className="br-form">
                        <div className="actions justify-content-start">
                            <button type="button" className="button is-secondary botao-navegacao" disabled={! props.arquivo} onClick={() => {download(props.arquivo)}}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                            </button>

                            {(totalPaginas > 1) &&
                                <Fragment>

                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual >= totalPaginas} onClick={irUltimaPagina}>
                                        <i className="fa fa-fast-forward" aria-hidden="true"></i>
                                    </button>

                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual >= totalPaginas} onClick={irPaginaPosterior}>
                                        <i className="fa fa-step-forward" aria-hidden="true"></i>
                                    </button>
                                    
                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual <= 1} onClick={irPaginaAnterior}>
                                        <i className="fa fa-step-backward" aria-hidden="true"></i>
                                    </button>

                                    <button type="button" className="button is-secondary botao-navegacao" disabled={paginaAtual <= 1} onClick={irPrimeiraPagina}>
                                        <i className="fa fa-backward" aria-hidden="true"></i>
                                    </button>

                                </Fragment>
                            }
                        </div>
                    </div>
                    <br/>
                    <Document file={props.arquivo} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={paginaAtual} scale={2.5}/>
                    </Document>
                </Fragment>
            }
        </Fragment>
    )
    
}