import React, { Component } from "react";
import { withRouter, Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Pagination } from "../Pagination/Pagination";
import {getMessagemErro} from "../../utils/erro";
import {formataDataHora} from '../../utils/formatadorData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

import api from "../../services/api";
import { baixarArquivo } from "../../utils/downloadUtil";

import './SolicitacaoCadastroWhitelist.css';
import { CD_AGUARDANDO_AUTORIZACAO, CD_AUTORIZADA, SITUACAO_AGUARDANDO_AUTORIZACAO, SITUACAO_AUTORIZADA, SITUACAO_EM_PROCESSAMENTO, SITUACAO_PROCESSADA } from "../../providers/constSituacoes";
import { CD_INCLUSAO, CD_EXCLUSAO, OPERACAO_INCLUSAO, OPERACAO_EXCLUSAO } from "../../providers/constOperacoesWhitelist";

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const SolicitacaoCadastroWhitelistList =

// router
withRouter(
// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(
	class extends Component {
	
		constructor(props){
			super(props);
			this.state = {
				criterioPesquisa: {
					numero: '',
					orgao: '',
					situacao: '',
					operaao: '',
				},
				orgaos: [],
				situacoes: [SITUACAO_AGUARDANDO_AUTORIZACAO, SITUACAO_AUTORIZADA, SITUACAO_EM_PROCESSAMENTO, SITUACAO_PROCESSADA],
				operacoes: [OPERACAO_INCLUSAO, OPERACAO_EXCLUSAO],
				resultadoPesquisa: {},
				pesquisaExecutada: false,
				botoesHabilitados : true,
				exibeModalConfirmacao: false,
				msgModal: '',
				operacao: null,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.fechaModal = this.fechaModal.bind(this);
			this.podeEditarExcluir = this.podeEditarExcluir.bind(this);
			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.excluirSolicitacao = this.excluirSolicitacao.bind(this);
			this.autorizarSolicitacao = this.autorizarSolicitacao.bind(this);
			this.mostraRelatorioCSV = this.mostraRelatorioCSV.bind(this);
			this.mostraRelatorioPDF = this.mostraRelatorioPDF.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					
					<div style={{margin: "10px"}}>
						<h1>Solicitações de liberação de IPs</h1>
					</div>
					
					<div className="div-form">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();
			
			const user = this.props.user;
			if(user.isAdministrador || user.isMantenedor){
				api.get('/api/orgao/perfil-usuario?ativos=true')
					.then(response => { 
						this.setState({orgaos: response.data }) 
					})
					.catch(erro => {
						const msg = getMessagemErro("Não foi possível recuperar os órgãos.", erro);
						console.log(JSON.stringify(msg));
						this.props.exibeMensagemErro(msg.mensagem, msg.erros);
					});
			}
		}

		renderForm() {
			const user = this.props.user;

			const { criterioPesquisa, resultadoPesquisa, pesquisaExecutada, orgaos, situacoes, operacoes } = this.state;
			const { content } = resultadoPesquisa;
			const exibeBotoes = content && content.length > 0;
			const { numero, orgao, situacao, operacao } = criterioPesquisa;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalConfirmacao}
						handleOk={() => this.executaOperacao()}
						handleCancel={() => this.fechaModal()}
						message={this.state.msgModal}/>

					<div className="br-form">
						{ (user.isAdministrador || user.isMantenedor) &&
							<div className="row">
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>Órgão:</label>
											<select name="orgao" onChange={this.handleInputChange} value={orgao}>
												<option value="">Todos</option>
													{
														orgaos.map(orgao =>
															<option 
																key={orgao.id} 
																value={orgao.id}>
																{orgao.nome}
															</option>
													)}
											</select>
										</div>
									</div>
								</div>
							</div>
						}

						<div className="row">
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Número solicitação:</label>
										<input type="text" name="numero" 
											value={numero}  
											onChange={this.handleInputChange} maxLength={11}/>
										
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Operacao:</label>
										<select name="operacao" onChange={this.handleInputChange} value={operacao}>
												<option value="">Todas</option>
												{
													operacoes.map(operacao =>
														<option 
															key={operacao.codigo} 
															value={operacao.codigo}>
															{operacao.nome}
														</option>
												)}
											</select>
									</div>
								</div>
							</div>
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label>Situação:</label>
										<select name="situacao" onChange={this.handleInputChange} value={situacao}>
												<option value="">Todas</option>
												{
													situacoes.map(situacao =>
														<option 
															key={situacao.codigo} 
															value={situacao.codigo}>
															{situacao.nome}
														</option>
												)}
											</select>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={() => this.pesquisar()} disabled={!this.state.botoesHabilitados}>Pesquisar</button>
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioPDF} disabled={!this.state.botoesHabilitados}>Relatório PDF</button>}
								{exibeBotoes === true &&	<button type="button" className="button is-secondary" onClick={this.mostraRelatorioCSV} disabled={!this.state.botoesHabilitados}>Relatório CSV</button>}
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()} disabled={!this.state.botoesHabilitados}>Limpar</button>
								<Link to="/solicitacao-cadastro-whitelist/new"> 
									<button type="button" className="button is-secondary mr-3" disabled={!this.state.botoesHabilitados}>Nova</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary" disabled={!this.state.botoesHabilitados}>Voltar</button>
									</div>
								</Link>
							</div>
						</div>
					
						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">Número</th>
											<th scope="col">Operação</th>
											<th scope="col">Órgão</th>
											<th scope="col">CIDR's</th>
											<th scope="col">Situação</th>
											<th scope="col">Data da Situação</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((solicitacao, index) =>
													<tr key={index} >
														<td>{solicitacao.numero}</td>
														<td>{solicitacao.nomeOperacao}</td>
														<td>{solicitacao.nomeOrgao}</td>
														{ CD_INCLUSAO === solicitacao.operacao &&
															<td>
																{(solicitacao.cidrs && solicitacao.cidrs.length > 0) && 
																	<ul>
																		{solicitacao.cidrs.map((cidr, i) => this.getCidrAsLi(cidr, i))}
																	</ul>
																}
															</td>
														}
														{ CD_EXCLUSAO === solicitacao.operacao &&
															<td>
																{(solicitacao.whitelistExclusao && solicitacao.whitelistExclusao.length > 0) && 
																	<ul>
																		{solicitacao.whitelistExclusao.map((cidr, i) => this.getCidrAsLi(cidr, i))}
																	</ul>}
															</td>
														}
														<td>{solicitacao.nomeSituacao}</td>
														<td>{formataDataHora(solicitacao.dataSituacao)}</td>
														<td className="text-center">
															<div className="actions">
																{ this.podeEditarExcluir(solicitacao) &&
																	<button type="button" onClick={() => this.confirmaOperacao(solicitacao, this.excluirSolicitacao, "Deseja realmente excluir a solicitação de liberação de IPs?")} 
																			disabled={!this.state.botoesHabilitados}  
																			title="Excluir a solicitação de liberação de IPs">
																		<span className="sr-only">Excluir</span>
																		<i className="fas fa-trash" id="danger"></i>
																	</button>
																}

																{ this.podeEditarExcluir(solicitacao) &&
																	<Link to={`/solicitacao-cadastro-whitelist/edit/${solicitacao.id}`}>
																		<button type="button" disabled={!this.state.botoesHabilitados} title="Editar a solicitação de liberação de IPs">
																			<span className="sr-only">Editar</span>
																			<i className="fas fa-edit"></i>
																		</button>
																	</Link>
																}

																{ ((user.isAdministrador || user.isMantenedor) && solicitacao.situacao === CD_AGUARDANDO_AUTORIZACAO) &&
																	<button type="button" onClick={() => this.confirmaOperacao(solicitacao, this.autorizarSolicitacao, "Deseja realmente autorizar a execução da solicitação de liberação de IPs?")} 
																			disabled={!this.state.botoesHabilitados}  
																			title="Autorizar a execução da solicitação de liberação de IPs">
																		<span className="sr-only">Autorizar</span>
																		<i className="fas fa-check"></i>
																	</button>
																}

															</div>
														</td>
													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhuma solicitação encontrada com os critérios informados.
							</div>
						}
					</div>
				</div>
			);
		}

		getCidrAsLi(cidr, i) {
			const url = `https://registro.br/tecnologia/ferramentas/whois?search=${cidr.cidr}`;
			const help = `Consultar o titular do CIDR ${cidr.cidr} (abre em outra aba)`;
			return (<li key ={i}>
						{cidr.nomeTipoAmbiente} - {cidr.cidr}
						&nbsp;<a href={url} target="blank" title={help}>
							<FontAwesomeIcon icon={faMagnifyingGlass} size="lg"/> 
						</a>
						<br/>
						{cidr.firstIp} {' => '} {cidr.lastIp}
						<br/>
						({cidr.countIpAsString} IPs)
				</li>);
		}

		podeEditarExcluir(solicitacao) {
			const user = this.props.user;

			if (user.isAdministrador || user.isMantenedor) {
				if (solicitacao.situacao === CD_AGUARDANDO_AUTORIZACAO || solicitacao.situacao === CD_AUTORIZADA) {
					return true;
				}
			} else {
				if (solicitacao.situacao === CD_AGUARDANDO_AUTORIZACAO) {
					return true;
				}
			}

			return false;
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;

			const criterioPesquisa = this.state.criterioPesquisa;
			criterioPesquisa[nome] = valor;
			this.setState({ criterioPesquisa });
		}

		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada (pagina){
			const {criterioPesquisa} = this.state;
			criterioPesquisa['pagina'] = pagina;
			const url = '/api/solicitacao-cadastro-whitelist/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando();
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar as solicitações de liberação de IP. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then(() => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.setState(
				{
					criterioPesquisa: {
						numero: '',
						orgao: '',
						situacao: '',
						operacao: '',
					},
					resultadoPesquisa: {},
					pesquisaExecutada: false,
					exibeModalConfirmacao: false,
					botoesHabilitados : true,
				}
			)
		}

		confirmaOperacao(solicitacao, operacao, msgModal) {
			this.setState(
				{
					exibeModalConfirmacao: true,
					solicitacaoAlvo: solicitacao,
					operacao,
					msgModal,
				}
			);
		}

		executaOperacao () {
			this.setState({botoesHabilitados:false});
			this.props.limpaMensagem();
			this.props.mostraAguardando();
			window.scrollTo(0, 0)

			this.state.operacao(this.state.solicitacaoAlvo);
			this.fechaModal();
		}

		excluirSolicitacao (solicitacao){
			api.delete(`/api/solicitacao-cadastro-whitelist/${solicitacao.id}`)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					this.props.exibeMensagemSucesso("Solicitação de liberação de IPs excluída com sucesso.");
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível excluir a solicitação de liberação de IPs.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		autorizarSolicitacao (solicitacao){
			api.put(`/api/solicitacao-cadastro-whitelist/autorizar/${solicitacao.id}`)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					this.props.exibeMensagemSucesso("Autorização da execução da solicitação de liberação de IPs realizada com sucesso.");
				})
				.catch(erro => {
					const msg = getMessagemErro("Não foi possível autorizar a execução da solicitação de liberação de IPs.", erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then (() =>{
					this.setState({botoesHabilitados:true});
					this.props.escondeAguardando();
				});
		}

		fechaModal () {
			this.setState(
				{
					exibeModalConfirmacao: false,
				}
			);
		}

		mostraRelatorioCSV(e){
			this.mostraRelatorio(e, 'csv');
		}

		mostraRelatorioPDF(e){
			this.mostraRelatorio(e, 'pdf');
		}

		mostraRelatorio(e, tipo){
			const { criterioPesquisa } = this.state;
			const url = `/api/solicitacao-cadastro-whitelist/${tipo}`;

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa,
				responseType: 'arraybuffer'
			}

			this.props.mostraAguardando();
			this.setState({botoesHabilitados : false});

			api(options)
				.then((response) => {
					baixarArquivo(new Blob([response.data]), `RelatorioSolicitacoesLiberacaoIPs.${tipo}`);
				})				
				.catch(response => {
					const erro = {data: JSON.parse(Buffer.from(response.data).toString('utf8'))};
					const mensagemInicial = 'Não foi possível recuperar o relatório de solicitações de liberação de IPs';
					const msg = getMessagemErro(mensagemInicial, erro);
					console.log(JSON.stringify(msg));
					this.props.exibeMensagemErro(msg.mensagem, msg.erros);
				})
				.then(() => {
					this.props.escondeAguardando();
					this.setState({botoesHabilitados : true});
				});
		}
	}

) // redux
); // router