import React, { Component } from "react";
import { withRouter , Link} from "react-router-dom";
import { connect } from 'react-redux';
import { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem } from "../../providers/actions";
import { mostraAguardando, escondeAguardando } from "../../providers/actions";
import { Message } from "../Message/Message";
import { Loading } from "../Loading/Loading";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Pagination } from "../Pagination/Pagination";

import api from "../../services/api";
import './Manager.css';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, limpaMensagem, mostraAguardando, escondeAguardando }

const mapStateToProps = (state) => {
	return {
		user: state.usuarioStore.user,
	}
}

export const ManagerList =

withRouter(

// redux
connect(
	(mapStateToProps), 
	(mapDispatchToProps)
)(

	class extends Component {

		constructor(props){
			super(props);
			this.state = {
				orgaos: [],
				orgao: '',
				fornecedoras: [],
				fornecedorasTodas: [],
				fornecedora: '',
				resultadoPesquisa: {},
				cpf: '',
				nome: '',
				ativos: '',
				pesquisaExecutada : false,
				exibeModalAtivar: false,
				exibeModalInativar: false,
			};
			this.handleInputChange = this.handleInputChange.bind(this);
			this.handleOrgaoChange = this.handleOrgaoChange.bind(this);
			this.pesquisar = this.pesquisar.bind(this);
			this.executaPesquisaPaginada = this.executaPesquisaPaginada.bind(this);
			this.limparPesquisa = this.limparPesquisa.bind(this);
			this.confirmaOperacao = this.confirmaOperacao.bind(this);
			this.fechaModais = this.fechaModais.bind(this);
			this.handleOkModais = this.handleOkModais.bind(this);
		}

		render() {
			return (
				<main id="content" className="page-content p-3">
					<Message />
					<Loading />
					<div style={{margin: "10px"}}>
						<h1>Gestores</h1>
					</div>
					<div className="div-form1">
						{this.renderForm()}
					</div>
				</main>
			);
		}

		componentDidMount(){
			this.props.limpaMensagem();

			api.get('/api/orgao/perfil-usuario')
			.then(response => { this.setState({orgaos: response.data}) })
			.catch(erro => {
				console.log("Não foi possível recuperar órgãos " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});

			api.get('/api/aplicacao-fornecedora')
			.then(response => { 
				const fornecedoras = response.data;

				this.setState(
					{
						fornecedoras,
						fornecedorasTodas: [...fornecedoras],
					}
				)
			 })
			.catch(erro => {
				console.log("Não foi possível recuperar as aplicações fornecedoras " + erro)
				this.props.exibeMensagemErro("Erro durante o carregamento da página")
			});
		}

		renderForm() {
			const { resultadoPesquisa, pesquisaExecutada } = this.state;
			const { content } = resultadoPesquisa;
			const usuario = this.props.user;
			const cpfUsuario = usuario.sub;

			return(
				<div className="container-fluid">

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalAtivar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente habilitar o gestor?"/>

					<ConfirmModal titulo="Confirmação" 
						visible={this.state.exibeModalInativar}
						handleOk={() => this.handleOkModais()}
						handleCancel={() => this.fechaModais()}
						message="Deseja realmente desabilitar o gestor?"/>

					<div className="br-form">
						<div className="row">
							<div className="col-md">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Órgao:</label>
										<select name="orgao" onChange={this.handleOrgaoChange} value={this.state.orgao}>
											<option value="">Todos</option>
											{
												this.state.orgaos.map(orgao =>
													<option 
														key={orgao.id} 
														value={orgao.id}>
														{orgao.nome}
													</option>
											)}
										</select>
									</div>
								</div>
							</div>

							{ usuario.isAdministrador && this.state.fornecedoras && this.state.fornecedoras.length > 0 &&
								<div className="col-md">
									<div className="field">
										<div className="input">
											<label>API:</label>
											<select name="fornecedora" onChange={this.handleInputChange} value={this.state.fornecedora}>
												<option value="">Todas</option>
													{
														this.state.fornecedoras.map(fornecedora =>
															<option 
																key={fornecedora.id} 
																value={fornecedora.id}>
																{fornecedora.nome}
															</option>
													)}
											</select>
										</div>
									</div>
								</div>
							}
							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">CPF:</label>
										<input type="text" name="cpf" 
											value={this.state.cpf}  
											onChange={this.handleInputChange} maxLength={11}/>
										
									</div>
								</div>
							</div>

							<div className="col-md">
								<div className="field">
									<div className="input">
										<label>Nome:</label>
										<input type="text" name="nome" 
											value={this.state.nome}  
											onChange={this.handleInputChange} />
									</div>
								</div>
							</div>

							<div className="col-sd">
								<div className="field">
									<div className="input">
										<label htmlFor="consumidora">Selecionar:</label>
										<select name="ativos" value={this.state.ativos} onChange={this.handleInputChange}>
											<option value="">Todos</option>
											<option value="true">Ativos</option>
											<option value="false">Inativos</option>	
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="actions-button">
							<div className="actions justify-content-start">
								<button type="button" className="button is-primary" onClick={this.pesquisar}>Pesquisar</button>
								<button type="button" className="button is-secondary" onClick={() => this.limparPesquisa()}>Limpar</button>
								<Link to="/manager/new"> 
									<button type="button" className="button is-secondary mr-3">Novo</button>
								</Link>
								<Link to="/"> 
									<div className="back-button">
										<button type="button" className="button is-secondary">Voltar</button>
									</div>
								</Link>
							</div>
						</div>

						{(content && content.length > 0) &&	
							<div className="br-table">						
								<div className="table">
									<table>
										<thead>
											<tr>
											<th scope="col">CPF</th>
											<th scope="col">Nome</th>
											<th scope="col">Órgão</th>
											{ usuario.isAdministrador === true && <th scope="col">Gestor API</th>}
											<th scope="col">Início Gestão</th>
											<th scope="col">Fim Gestão</th>
											<th className="text-center" scope="col">Ações</th>
											</tr>
										</thead>
										
										<tbody>
											{ content.map((gestor, index) =>
													<tr key={index} >
														<td>{gestor.cpf}</td>
														<td>{gestor.nome}</td>
														<td>{gestor.nomeOrgao}</td>

														{ usuario.isAdministrador === true && 
															<td>
															{(gestor.apisGestor && gestor.apisGestor.length > 0) && <ul>{gestor.apisGestor.map(api => <li>{api.nome}</li>)}</ul>}
															</td>
														}

														<td>{gestor.inicioGestao}</td>
														<td>{gestor.fimGestao}</td>

														<td className="text-center">
															<div className="actions">
																{ usuario.isAdministrador === true && gestor.cpf !== cpfUsuario &&  
																<Link to={`/manager/edit/${gestor.cpf}`}>
																	<button type="button" title="Editar o gestor">
																		<span className="sr-only">Editar</span>
																		<i className="fas fa-edit"></i>
																	</button>
																</Link>
																}
											
																{ usuario.isAdministrador === true && gestor.cpf !== cpfUsuario && ! gestor.fimGestao &&
																	<button type="button" onClick={() => this.confirmaOperacao(gestor)} title="Desabilitar o gestor">
																		<span className="sr-only">Desativar</span>
																		<i className="fas fa-power-off" id="on"></i>
																	</button>
																}
																
																{ usuario.isAdministrador === true && gestor.cpf !== cpfUsuario && gestor.fimGestao &&
																	<button type="button" onClick={() => this.confirmaOperacao(gestor)} title="Habilitar o gestor">
																		<span className="sr-only">Ativar</span>
																		<i className="fas fa-power-off" id="off"></i>
																	</button>
																}
															</div>
														</td>


													</tr>
												)}
										</tbody>
									</table>
									<Pagination totalPaginas={resultadoPesquisa.totalPages} paginaAtual={resultadoPesquisa.number} onChangePage={this.executaPesquisaPaginada}/>
								</div>
							</div>
						}

						{(pesquisaExecutada && (! content || content.length === 0)) &&
							<div>
								Nenhum gestor encontrado com os critérios informados.
							</div>
						}

					</div>
				</div>

				
			);
		}

		handleInputChange(evento){
			const target = evento.target;
			const nome = target.name;
			const valor = target.type === 'checkbox' ? target.checked : target.value;
			this.setState({[nome]: valor});
		}

		handleOrgaoChange(evento){
			const idOrgao =  evento.target.value;
			let fornecedoras = [];

			const usuario = this.props.user;

			if(usuario.isAdministrador === true && idOrgao && idOrgao !== ""){
				this.props.mostraAguardando();
				api.get(`/api/aplicacao-fornecedora/orgao/${idOrgao}`)
				.then(response => { 
					this.setState(
						{
							fornecedoras: response.data,
						}
					);
					this.props.escondeAguardando();
				})
				.catch(erro => {
					this.props.escondeAguardando();
					console.log("Não foi possível recuperar as aplicações fornecedoras associadas ao órgão " + JSON.stringify(erro));
					this.props.exibeMensagemErro("Erro durante o carregamento das API's do órgão.")
				});
			} else {
				fornecedoras = this.state.fornecedorasTodas;
			}

			this.setState({
				orgao: idOrgao,
				fornecedora: '',
				fornecedoras,
			});
		}


		pesquisar (e){
			this.props.limpaMensagem();
			this.executaPesquisaPaginada(0);
		}

		executaPesquisaPaginada(pagina){

			const {nome, cpf, orgao, fornecedora, ativos} = this.state;
			const criterioPesquisa = {nome, cpf, orgao, fornecedora, ativos, pagina};
			const url = '/api/gestor/paginado';

			const options = {
				method: 'post',
				url,
				data: criterioPesquisa
			}

			this.props.mostraAguardando()
			api(options)
				.then(response => { 
					this.setState({
						resultadoPesquisa: response.data,
						pesquisaExecutada : true,
					}) 
				})
				.catch(erro => {
					console.log("Não foi possível recuperar os gestores. " + erro)
					this.props.exibeMensagemErro("Erro durante a execução da pesquisa")
				})
				.then( () => {
					this.props.escondeAguardando();
				});
		}

		limparPesquisa(){
			this.props.limpaMensagem();
			this.setState({
				orgao: '',
				nome: '',
				fornecedora: '',
				fornecedoras: [...this.state.fornecedorasTodas],
				cpf: '',
				ativos: '',
				resultadoPesquisa: {},
				pesquisaExecutada : false,
			});
		}

		alterarSituacao (gestor){
			this.props.mostraAguardando();
			this.props.limpaMensagem();
			window.scrollTo(0, 0);

			api.put(`/api/gestor/situacao/${gestor.cpf}/${gestor.fimGestao ? true : false}`)
				.then(response => { 
					this.setState({
						pesquisaExecutada : true,
					}) 
					this.pesquisar();
					let mensagem = gestor.fimGestao === null ? 'Gestor desabilitado com sucesso!' : 'Gestor habilitado com sucesso!';
					this.props.exibeMensagemSucesso(mensagem);
				})
				.catch(erro => {
					console.log("Não foi possível alterar a situação do gestor. " + erro)
					let mensagem = "Não foi possível alterar a situação do gestor.";
					if(erro.data && erro.data.mensagem){
						mensagem = erro.data.mensagem;
					}
					
					this.props.exibeMensagemErro(mensagem);
				}).then(() => {
					this.props.escondeAguardando();
				});
		}

		handleOkModais () {
			this.alterarSituacao(this.state.gestorAlvo);
			this.fechaModais();
		}

		confirmaOperacao(gestor) {
			this.setState(
				{
					exibeModalAtivar:  gestor.fimGestao,
					exibeModalInativar: ! gestor.fimGestao,
					gestorAlvo: gestor,
				}
			);
		}

		fechaModais () {
			this.setState(
				{
					exibeModalAtivar:false,
					exibeModalInativar:false
				}
			);
		}
	}

) // redux
); // router