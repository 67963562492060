import React, { Component, Fragment } from "react";
import { Modal } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

export const ConfirmModal =

class extends Component {
	render() {
		return <Modal
            title={<Fragment>
                      <FontAwesomeIcon icon={faQuestionCircle} size="2x" style={{color: "#C9302C"}}/> {this.props.titulo}
                    </Fragment>
                  }
		      open={this.props.visible}
          onOk={this.props.handleOk}
          onCancel={this.props.handleCancel}
          maskClosable = {false}
          centered
        >
          <p>{this.props.message}</p>
        </Modal>
	}

}